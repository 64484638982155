<template>
	<div class="views-questions" v-loading="!isUpdateExamsPaperStatusOk">
		<div class="views-questions-answer" v-show="activeName == 'answer'">
			<div class="answer-body M" v-if="examsQuestion">
				<div class="answer-body-main">
					<div class="questions">
						<Estimate
							v-if="examsQuestion.questionType == 'judgment'"
							ref="Estimate"
							:key="examsQuestion.id"
							:examsQuestionListIndex="examsQuestionListIndex"
							:examsQuestion="examsQuestion"
							:details="details"
						></Estimate>
						<Operation
							v-if="examsQuestion.questionType == 'operation'"
							ref="Operation"
							:key="examsQuestion.id"
							:examsQuestionListIndex="examsQuestionListIndex"
							:examsQuestion="examsQuestion"
							:details="details"
						></Operation>
						<Discuss
							v-if="examsQuestion.questionType == 'discussion'"
							ref="Discuss"
							:key="examsQuestion.id"
							:examsQuestionListIndex="examsQuestionListIndex"
							:examsQuestion="examsQuestion"
							:details="details"
						></Discuss>
					</div>
					<div class="btns">
						<div class="previous-btn" @click="previous()">
							<el-button>上一题</el-button>
						</div>
						<div class="next-btn" @click="next()">
							<el-button type="custom-primary">下一题</el-button>
						</div>
					</div>
				</div>
				<div class="answer-body-side custom-scrollbar-y">
					<div class="scrollbar-content">
						<div class="count-down">
							<div class="title">倒计时</div>
							<div class="time-box">
								<svg-icon name="sandclock"></svg-icon>
								<div class="time">{{ answerRemainingTime }}</div>
							</div>
						</div>
						<div class="answer-card">
							<div class="card-header">
								<div class="title">答题卡</div>
								<div class="right-box">
									<div class="yes">
										<div class="dot"></div>
										<div class="label">已答</div>
									</div>
									<div class="no">
										<div class="label">未答</div>
									</div>
								</div>
							</div>
							<div class="card-body">
								<div class="list">
									<div
										class="item"
										:class="{
											active:
												examsPaperAnswerCache &&
												(examsPaperAnswerCache.find((v) => v.id == item.questionId).answerOption ||
													examsPaperAnswerCache.find((v) => v.id == item.questionId).answerContent)
										}"
										v-for="(item, index) in examsQuestionList"
										:key="item.id"
										@click="switchQuestion(index)"
									>
										<!-- <div class="item-content">{{ item.sequence < 9 ? `0${item.sequence}` : item.sequence }}</div> -->
										<div class="item-content">{{ index < 9 ? `0${index + 1}` : index + 1 }}</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="tip">*提交后不可修改</div>
								<div class="submit-btn" @click="submit()">
									<el-button type="custom-primary">提交</el-button>
								</div>
								<div class="save-btn" @click="save()">
									<el-button>保存</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 暂存 -->
		<div class="views-questions-saved" v-show="activeName == 'saved'">
			<div class="saved-body M">
				<svg-icon name="saved"></svg-icon>
				<div class="status">答题已保存</div>
				<div class="tip">请在截止前提交试卷</div>
				<div class="btn">
					<el-button @click="continueAnswer()">继续答题</el-button>
				</div>
			</div>
		</div>

		<!-- 提交页面 -->
		<div class="views-questions-committed" v-show="activeName == 'committed'">
			<div class="committed-body M">
				<svg-icon name="committed"></svg-icon>
				<div class="status">答题提交成功</div>
				<div class="tip">等待专家老师阅卷</div>
				<!-- <div class="btn" :style="(examsPaperStatus.timeFlag == 1 && isUpdateExamsPaperStatusOk) | visibilityFilter">
					<el-button @click="editAnswer()">修改答题</el-button>
				</div> -->
			</div>
		</div>

		<!-- 考试结束 -->
		<div class="views-questions-finished" v-show="activeName == 'finished'">
			<div class="finished-body M">
				<svg-icon name="committed"></svg-icon>
				<div class="status">考试已结束</div>
				<div class="tip">等待专家老师阅卷</div>
			</div>
		</div>

		<!-- 暂无考试 -->
		<div class="views-questions-noPaper" v-show="activeName == 'noPaper'">
			<div class="noPaper-body M">
				<svg-icon name="noPaper"></svg-icon>
				<div class="status">暂无考试</div>
				<div class="tip">请联系管理员查询考试计划</div>
			</div>
		</div>

		<!-- 考试未开始 -->
		<div class="views-questions-notStarted" v-show="activeName == 'notStarted'">
			<div class="notStarted-body M">
				<svg-icon name="notStarted"></svg-icon>
				<div class="status">考试暂未开始</div>
				<div class="tip">距离考试开始时间还有{{ notStartedRemainingTime }}</div>
			</div>
		</div>

		<!-- 评分 -->
		<div class="views-questions-scored" v-show="activeName == 'scored'">
			<div class="scored-body M">
				<div class="card">
					<div class="benediction">恭喜你，{{ userInfo.name }}</div>
					<div class="score-label">考试得分</div>
					<div class="score">{{ acquireScores }}</div>
					<div class="comment-label">专家评语</div>
					<div class="comment">{{ overallEvaluation }}</div>
					<div class="btn">
						<el-button type="custom-primary" @click="toAnalysis()">查看详情</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ExamsPaper, ExamsPaperSave, ExamsPaperSubmit } from '@/common/api.js';
import Estimate from '@/views/questions/answer/components/Estimate.vue';
import Operation from '@/views/questions/answer/components/Operation.vue';
import Discuss from '@/views/questions/answer/components/Discuss.vue';
export default {
	components: {
		Estimate,
		Operation,
		Discuss
	},
	data() {
		return {
			answerRemainingTime: '',
			answerInterval: null,
			examsQuestionList: [],
			examsQuestionListIndex: 0,
			details: {},
			activeName: '',
			isUpdateExamsPaperStatusOk: false,
			startTime: '',
			endTime: '',
			notStartedRemainingTime: '',
			notStartedInterval: null
		};
	},
	computed: {
		examsQuestion() {
			let result = {};
			if (this.examsQuestionListIndex > -1) {
				result = this.examsQuestionList[this.examsQuestionListIndex];
			}
			return result;
		},
		acquireScores() {
			let result = '';
			if (this.details.id && this.activeName == 'scored') {
				result = this.details.paperRecord.acquireScores;
			}
			return result;
		},
		overallEvaluation() {
			let result = '';
			if (this.details.id && this.activeName == 'scored') {
				result = this.details.paperRecord.overallEvaluation ? this.details.paperRecord.overallEvaluation : '无';
			} else {
				result = '无';
			}
			return result;
		}
	},
	created() {
		document.documentElement.style.overflowY = 'auto';
		this.getExamsPaper();
	},
	beforeDestroy() {
		document.documentElement.style.overflowY = 'auto';
		// 清除定时器
		if (this.answerInterval) {
			clearInterval(this.answerInterval);
		}
		if (this.notStartedInterval) {
			clearInterval(this.notStartedInterval);
		}
	},
	methods: {
		toAnalysis() {
			this.openUrl({
				name: 'analysis'
			});
		},
		editAnswer() {
			this.activeName = 'answer';
		},
		continueAnswer() {
			this.activeName = 'answer';
		},
		refresh() {
			this.getExamsPaper();
		},
		getExamsPaper() {
			this.examsQuestionListIndex = 0;
			this.isUpdateExamsPaperStatusOk = false;
			this.$store
				.dispatch('updateExamsPaperStatus')
				.then((res) => {
					if (this.answerInterval) {
						clearInterval(this.answerInterval);
					}
					if (this.notStartedInterval) {
						clearInterval(this.notStartedInterval);
					}

					if (this.examsPaperStatus.noPaper == 1) {
						this.activeName = 'noPaper';
					}

					if (this.examsPaperStatus.timeFlag == 0) {
						this.details = res.data;
						this.activeName = 'notStarted';
						this.startTime = new Date(this.details.startTime);
						this.endTime = new Date(this.details.endTime);
						this.notStartedInterval = setInterval(this.updateNotStartedTime, 1000);
					}

					if (this.examsPaperStatus.timeFlag == 1) {
						if (this.examsPaperStatus.inspectionFlag == 0) {
							// 0 未批阅 1 已批阅 2 已发布
							this.details = res.data;
							this.startTime = new Date(this.details.startTime);
							this.endTime = new Date(this.details.endTime);
							this.answerInterval = setInterval(this.updateAnswerTime, 1000);
							if (!res.data.paperRecord.questionRecordList) {
								this.examsQuestionList = [];
							} else {
								this.examsQuestionList = res.data.paperRecord.questionRecordList;
								// 答题回显
								let examsPaperAnswerCache = this.examsQuestionList.map((v) => {
									let obj = {
										id: v.questionId,
										answerOption: v.answerOption,
										answerContent: v.answerContent
									};
									return obj;
								});
								this.$store.commit('changeExamsPaperAnswerCache', examsPaperAnswerCache);
							}

							if (this.examsPaperStatus.finishedFlag == 0) {
								// 0 未完成 1 已完成
								this.activeName = 'answer';
							}

							if (this.examsPaperStatus.finishedFlag == 1) {
								this.activeName = 'committed';
							}
						}

						if (this.examsPaperStatus.inspectionFlag == 1) {
							this.details = res.data;
							this.activeName = 'scored';
						}

						if (this.examsPaperStatus.inspectionFlag == 2) {
							this.details = res.data;
							this.activeName = 'scored';
						}
					}

					if (this.examsPaperStatus.timeFlag == 2) {
						if (this.examsPaperStatus.inspectionFlag == 0) {
							// 0 未批阅 1 已批阅 2 已发布
							this.activeName = 'finished';
						}
						if (this.examsPaperStatus.inspectionFlag == 1) {
							this.details = res.data;
							this.activeName = 'scored';
						}
						if (this.examsPaperStatus.inspectionFlag == 2) {
							this.details = res.data;
							this.activeName = 'scored';
						}
					}
				})
				.finally(() => {
					this.isUpdateExamsPaperStatusOk = true;
					this.$bus.$emit('backTop');
				});
		},
		submit() {
			let examsPaperAnswerCache = JSON.parse(sessionStorage.getItem('examsPaperAnswerCache')) || [];
			let data = {
				paperId: this.details.id,
				questionJson: examsPaperAnswerCache
			};
			ExamsPaperSubmit(data).then((res) => {
				let nowTime = new Date();
				let endTime = new Date(this.details.endTime);
				if (nowTime < endTime) {
					this.activeName = 'committed';
				} else {
					this.activeName = 'finished';
				}
			});
		},
		save() {
			let examsPaperAnswerCache = JSON.parse(sessionStorage.getItem('examsPaperAnswerCache')) || [];
			let data = {
				paperId: this.details.id,
				questionJson: examsPaperAnswerCache
			};
			ExamsPaperSave(data).then((res) => {
				this.activeName = 'saved';
			});
		},
		previous() {
			if (this.examsQuestionListIndex > 0) {
				this.examsQuestionListIndex--;
				this.$bus.$emit('backTop');
			}
		},
		next() {
			if (this.examsQuestionListIndex < this.examsQuestionList.length - 1) {
				this.examsQuestionListIndex++;
				this.$bus.$emit('backTop');
			}
		},
		switchQuestion(index) {
			this.examsQuestionListIndex = index;
		},
		updateAnswerTime() {
			if (this.notStartedInterval) {
				clearInterval(this.notStartedInterval);
			}
			let hh = '';
			let mm = '';
			let ss = '';
			if (this.startTime && this.endTime) {
				let nowTime = new Date();
				let startTime = new Date(this.startTime);
				let endTime = new Date(this.endTime);
				if (nowTime < startTime) {
					hh = '00';
					mm = '00';
					ss = '00';
					this.toast({
						message: '考试还未开始',
						type: 'warning'
					});
				} else if (nowTime > startTime && nowTime < endTime) {
					let currentTime = nowTime.getTime();
					let targetTime = endTime.getTime();
					let timestamp = targetTime - currentTime;
					let h = Math.floor(timestamp / 1000 / 3600);
					let m = Math.floor(((timestamp / 1000) % 3600) / 60);
					let s = Math.floor((timestamp / 1000) % 60);
					if (h < 0) {
						hh = '00';
					} else if (h < 10) {
						hh = `0${h}`;
					} else {
						hh = h;
					}
					if (m < 0) {
						mm = '00';
					} else if (m < 10) {
						mm = `0${m}`;
					} else {
						mm = m;
					}
					if (s < 0) {
						ss = '00';
					} else if (s < 10) {
						ss = `0${s}`;
					} else {
						ss = s;
					}
				} else {
					hh = '00';
					mm = '00';
					ss = '00';
					clearInterval(this.answerInterval);
					this.submit();
				}
			} else {
				hh = '00';
				mm = '00';
				ss = '00';
			}
			this.answerRemainingTime = `${hh}：${mm}：${ss}`;
		},
		updateNotStartedTime() {
			if (this.answerInterval) {
				clearInterval(this.answerInterval);
			}
			let hh = '';
			let mm = '';
			let ss = '';
			if (this.startTime && this.endTime) {
				let nowTime = new Date();
				if (nowTime < this.startTime) {
					let currentTime = nowTime.getTime();
					let targetTime = this.startTime.getTime();
					let timestamp = targetTime - currentTime;
					let h = Math.floor(timestamp / 1000 / 3600);
					let m = Math.floor(((timestamp / 1000) % 3600) / 60);
					let s = Math.floor((timestamp / 1000) % 60);
					if (h < 0) {
						hh = '00';
					} else if (h < 10) {
						hh = `0${h}`;
					} else {
						hh = h;
					}
					if (m < 0) {
						mm = '00';
					} else if (m < 10) {
						mm = `0${m}`;
					} else {
						mm = m;
					}
					if (s < 0) {
						ss = '00';
					} else if (s < 10) {
						ss = `0${s}`;
					} else {
						ss = s;
					}
				} else if (nowTime > this.startTime && nowTime < this.endTime) {
					clearInterval(this.notStartedInterval);
					setTimeout(() => {
						this.refresh();
					}, 1000);
				} else {
					clearInterval(this.notStartedInterval);
					setTimeout(() => {
						this.refresh();
					}, 1000);
				}
			} else {
				hh = '00';
				mm = '00';
				ss = '00';
			}
			this.notStartedRemainingTime = `${hh}小时${mm}分钟${ss}秒`;
		}
	}
};
</script>

<style lang="scss" scoped>
.views-questions {
	min-height: calc(100vh - $head-height);
}
.views-questions-answer {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.answer-body {
		flex: 1;
		display: flex;
		.answer-body-main {
			min-width: 970px;
			display: flex;
			flex-direction: column;
			.questions {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
			.btns {
				display: flex;
				margin: 60px 0;
				.previous-btn {
					margin-left: 322px;
					::v-deep {
						.el-button {
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 148px;
							height: 40px;
							border-radius: 4px 4px 4px 4px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
						}
					}
				}
				.next-btn {
					margin-left: 30px;
					::v-deep {
						.el-button {
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							width: 148px;
							height: 40px;
							border-radius: 4px 4px 4px 4px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
						}
					}
				}
			}
		}
		.answer-body-side {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			margin-top: 30px;
			position: sticky;
			top: calc($head-height + 30px);
			max-height: calc(100vh - $head-height - 30px);
			overflow-y: auto;
			width: 290px;
			.scrollbar-content {
				// width: calc(290px - 0px - 0px - $scrollbar-width - 2px);
				margin-right: 2px;
				display: flex;
				flex-direction: column;
				.count-down {
					display: flex;
					flex-direction: column;
					// width: 290px;
					// height: 120px;
					background: #ffffff;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
					border-radius: 4px 4px 4px 4px;
					border: 1px solid;
					border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
					box-sizing: border-box;
					padding-top: 20px;
					margin-bottom: 20px;
					.title {
						padding: 0 20px;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 16px;
						color: #000000;
						line-height: 16px;
					}
					.time-box {
						align-self: center;
						display: flex;
						align-items: center;
						margin: 30px 0;
						.svg-sandclock {
							font-size: 24px;
							margin-right: 8px;
						}
						.time {
							font-family: DIN Black, DIN Black;
							font-weight: 400;
							font-size: 24px;
							color: #151515;
							line-height: 24px;
						}
					}
				}
				.answer-card {
					display: flex;
					flex-direction: column;
					// width: 290px;
					min-height: 348px;
					background: #ffffff;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
					border-radius: 4px 4px 4px 4px;
					border: 1px solid;
					border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
					box-sizing: border-box;
					padding-top: 20px;
					padding-bottom: 20px;
					.card-header {
						padding: 0 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;
						.title {
							font-family: PingFang SC, PingFang SC;
							font-weight: bold;
							font-size: 16px;
							color: #000000;
							line-height: 16px;
						}
						.right-box {
							display: flex;
							.yes {
								display: flex;
								align-items: center;
								margin-left: 30px;
								.dot {
									margin-right: 6px;
									width: 6px;
									height: 6px;
									background: #019a7f;
									border-radius: 50%;
								}
								.label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 12px;
									color: #666666;
									line-height: 12px;
								}
							}
							.no {
								display: flex;
								align-items: center;
								margin-left: 30px;
								.label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 12px;
									color: #666666;
									line-height: 12px;
								}
							}
						}
					}
					.card-body {
						flex: 1;
						.list {
							margin-top: -14px;
							margin-left: -14px;
							margin-bottom: 20px;
							display: flex;
							flex-wrap: wrap;
							padding-left: 20px;
							padding-right: 20px;
							box-sizing: border-box;
							.item {
								width: calc(100% / 6);
								box-sizing: border-box;
								padding-top: 14px;
								padding-left: 14px;
								&.active {
									.item-content {
										border-color: #019a7f;
										background-color: #019a7f;
										color: #ffffff;
									}
								}
								.item-content {
									// width: 30px;
									height: 30px;
									background: #ffffff;
									border-radius: 4px 4px 4px 4px;
									border: 1px solid #ececec;
									box-sizing: border-box;
									display: flex;
									justify-content: center;
									align-items: center;
									font-family: PingFang SC, PingFang SC;
									font-weight: bold;
									font-size: 14px;
									color: #019a7f;
									line-height: 18px;
									cursor: pointer;
									white-space: nowrap;
									// &:hover {
									// 	border-color: #019a7f;
									// }
								}
							}
						}
					}
					.card-footer {
						display: flex;
						flex-direction: column;
						box-sizing: border-box;
						padding: 0 20px;
						.tip {
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
							color: #999999;
							text-align: center;
						}
						.submit-btn {
							margin-top: 10px;
							::v-deep {
								.el-button {
									width: 100%;
									padding: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									// width: 250px;
									height: 40px;
									border-radius: 4px 4px 4px 4px;
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 16px;
								}
							}
						}
						.save-btn {
							margin-top: 10px;
							::v-deep {
								.el-button {
									width: 100%;
									padding: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									// width: 250px;
									height: 40px;
									border-radius: 4px 4px 4px 4px;
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}
}

// 暂存
.views-questions-saved {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.saved-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.svg-saved {
			font-size: 120px;
		}
		.status {
			margin-top: 30px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #019a7f;
			line-height: 20px;
		}
		.tip {
			margin-top: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #666666;
			line-height: 16px;
		}
		.btn {
			margin-top: 60px;
			::v-deep {
				.el-button {
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					box-sizing: border-box;
					width: 164px;
					height: 40px;
					border-radius: 4px 4px 4px 4px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
	}
}
.views-questions-committed {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.committed-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.svg-committed {
			font-size: 120px;
		}
		.status {
			margin-top: 30px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #019a7f;
			line-height: 20px;
		}
		.tip {
			margin-top: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #666666;
			line-height: 16px;
		}
		.btn {
			margin-top: 60px;
			::v-deep {
				.el-button {
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					box-sizing: border-box;
					width: 164px;
					height: 40px;
					border-radius: 4px 4px 4px 4px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
	}
}
.views-questions-finished {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.finished-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.svg-committed {
			font-size: 120px;
		}
		.status {
			margin-top: 30px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #019a7f;
			line-height: 20px;
		}
		.tip {
			margin-top: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #666666;
			line-height: 16px;
		}
		.btn {
			margin-top: 60px;
			::v-deep {
				.el-button {
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					box-sizing: border-box;
					width: 164px;
					height: 40px;
					border-radius: 4px 4px 4px 4px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
	}
}
.views-questions-noPaper {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.noPaper-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.svg-noPaper {
			font-size: 120px;
		}
		.status {
			margin-top: 30px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #019a7f;
			line-height: 20px;
		}
		.tip {
			margin-top: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #666666;
			line-height: 16px;
		}
		.btn {
			margin-top: 60px;
			::v-deep {
				.el-button {
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					box-sizing: border-box;
					width: 164px;
					height: 40px;
					border-radius: 4px 4px 4px 4px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
	}
}
.views-questions-notStarted {
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.notStarted-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.svg-notStarted {
			font-size: 120px;
		}
		.status {
			margin-top: 30px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #019a7f;
			line-height: 20px;
		}
		.tip {
			margin-top: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #666666;
			line-height: 16px;
		}
		.btn {
			margin-top: 60px;
			::v-deep {
				.el-button {
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					box-sizing: border-box;
					width: 164px;
					height: 40px;
					border-radius: 4px 4px 4px 4px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}
	}
}
.views-questions-scored {
	background-color: $page-bg-dark;
	min-height: calc(100vh - $head-height);
	display: flex;
	flex-direction: column;
	.scored-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px;
		box-sizing: border-box;
		.card {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 326px;
			// height: 400px;
			background: #ffffff;
			border-radius: 10px 10px 10px 10px;
			padding: 50px 20px;
			box-sizing: border-box;
			.benediction {
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				font-size: 20px;
				color: #019a7f;
				line-height: 20px;
			}
			.score-label {
				margin-top: 40px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: #666666;
				line-height: 16px;
			}
			.score {
				margin-top: 16px;
				height: 40px;
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				font-size: 40px;
				color: #019a7f;
				line-height: 40px;
			}
			.comment-label {
				margin-top: 40px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 16px;
				color: #666666;
				line-height: 16px;
			}
			.comment {
				margin-top: 16px;
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				font-size: 16px;
				color: #333333;
				line-height: 16px;
				text-align: center;
			}
			.btn {
				margin-top: 40px;
				::v-deep {
					.el-button {
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						box-sizing: border-box;
						width: 164px;
						height: 40px;
						border-radius: 4px 4px 4px 4px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}
		}
	}
}
</style>
